import React from 'react';
import Home from './home';
import Privacy from './privacy';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {Container, Logo} from './styles';

function App() {
  return (
      <Router>
              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <Switch>
                  <Route path="/privacy">
                      <Privacy />
                  </Route>
                  <Route path="/">
                      <Home />
                  </Route>
              </Switch>
      </Router>
  );
}

export default App;
