import React from 'react';
import LogoImg from '../assets/logo.png';
import Balls from '../assets/brand-balls.png';

import {Container, BrandBalls, Logo} from '../styles';

function Home() {
    return (
        <Container>
            <BrandBalls src={Balls} />
            <Logo src={LogoImg} />
        </Container>
    );
}

export default Home;
