import styled from 'styled-components';

export const Content = styled.div`
  background-color: white;
  min-width: 760px;
  max-width: 760px;
  min-height: 100px;
  margin: 0 auto;
  align-self: center;
  padding: 25px;
`;
