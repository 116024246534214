import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(#3b36d0, #b600fb);
  margin: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img`
    max-width: 500px;
`;

export const BrandBalls = styled.img`
  max-width: 100px;
  margin-bottom: -40px;
`;
