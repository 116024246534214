import React from 'react';

import {Container, Logo} from '../styles';

import {Content} from './styles';

function Privacy() {
    return (
            <Content>
                <div className="post-entry post-entry-type-page post-entry-76">
                    <div className="entry-content-wrapper clearfix">
                        <section className="av_textblock_section ">
                            <div
                                className="avia_textblock  av_inherit_color  av-small-font-size-overwrite av-small-font-size-25 av-mini-font-size-overwrite av-mini-font-size-25"
                                ><h2 className="fw-500">Terms of Service</h2>
                            </div>
                        </section>
                        <div
                            className="hr hr-default  av-desktop-hide av-medium-hide av-small-hide av-mini-hide  avia-builder-el-4  el_after_av_textblock  el_before_av_textblock  ">
                            <span className="hr-inner "><span className="hr-inner-style"></span></span></div>
                        <section className="av_textblock_section ">
                            <div
                                className="avia_textblock   av-medium-font-size-overwrite av-medium-font-size-16 av-small-font-size-overwrite av-small-font-size-14 av-mini-font-size-overwrite av-mini-font-size-12"
                                ><h5>Last Updated: October 6, 2020</h5>
                                <p>These Terms of Service (this “Agreement”) is between you and Boop.
                                    (“Boop” “we,” “us,” or “our”). By using our website, located at
                                    http://www.goboop.me (“Website”), our mobile application available on
                                    Android and iOS devices (each, our “App”), and/or other services we provide
                                    (collectively, the “Services”)&nbsp;you agree to be bound by this Agreement. If you
                                    do not agree to be bound by this Agreement, you may not use the Services. Your use
                                    of, or participation in, certain Services may be subject to additional terms which
                                    will be presented to you when you access those Services.</p>
                                <h5>1. Some initial things we want to make clear.</h5>
                                <p>While all of these will be discussed in more detail below, we want to make a few
                                    important things clear up front:</p>
                                <ul>
                                    <li>You must be at least 18 to use the Services.</li>
                                    <li>You may only create an Account for your actual self and must be truthful in what
                                        you put in it.
                                    </li>
                                    <li>We do not conduct background checks or verify the identity of our Users.</li>
                                    <li>We do not guarantee anything about the Services, including the actions of our
                                        Users. We do not guarantee matches, the frequency of your matches, that you will
                                        be compatible with those you match with, or that the people you match with are
                                        safe people to meet. You must exercise your own diligence and caution in using
                                        the Services and communicating with its Users.
                                    </li>
                                    <li>You have a right in some states to cancel this Agreement within 3 business days
                                        of registering for a premium account and receive a refund of any payments to us
                                        if you do so. Please see Section 20for more details.
                                    </li>
                                    <li>Lastly, by using the Services, you are agreeing (with limited exception)&nbsp;to
                                        resolve any dispute between you and us through binding, individual arbitration,
                                        rather than in court. Please review Section 19below for details, including the
                                        procedure for opting out of arbitration.
                                    </li>
                                </ul>
                                <h5>2. Eligibility</h5>
                                <ul>
                                    <li><em><u>You must be at least 18, or older if your jurisdiction requires</u>.</em>You
                                        must be at least 18 years old (or, if the age of majority is higher than 18 in
                                        your jurisdiction, then the age of majority in your jurisdiction)&nbsp;create an
                                        Account and use the Services. By creating an Account and/or using the Services,
                                        you represent and warrant that you are at least 18 years old (or the minimum age
                                        of majority in your jurisdiction, whichever is greater).
                                    </li>
                                    <li><em><u>You must be unattached and available</u>.</em>You may only use the
                                        Services if you are willing and capable of entering into a new romantic
                                        relationship and do not have an established monogamous romantic partner
                                        (“Unattached”).
                                    </li>
                                    <li><em><u>You may not have a criminal record</u>.</em>You may not use the Services
                                        if you have been convicted of a felony, are required to register as a sex
                                        offender with any government entity, and/or are identified as a “Specially
                                        Designated National” (as such list may be updated from time to time, available
                                        at <a
                                            href="https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx">https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx</a>).
                                        If any of the foregoing become applicable to you after you have created an
                                        Account, you must delete your Account immediately and you may no longer use the
                                        Services. We do not conduct criminal background screenings on Users. However, we
                                        reserve the right to conduct any criminal background check, at any time and
                                        using available public records, to confirm your compliance with this subsection.
                                    </li>
                                </ul>
                                <h5>3. Use of the Service</h5>
                                <ul>
                                    <li><em><u>Creating an Account on Boop</u>.</em>In order to use Boop, you must create an account with us (an “Account”)&nbsp;by first
                                        logging in with your Facebook account or by providing your phone number. If you
                                        choose to create an Account by logging in with Facebook, you authorize us to
                                        access and use certain Facebook account information, including but not limited
                                        to your public Facebook profile photographs, personal information, and Facebook
                                        friends (to display common friends with other Users). For more information
                                        regarding the information we collect from you and how we use it, please consult
                                        our Privacy Policy.
                                    </li>
                                    <li><em><u>Your Account is only for you</u>.</em>You may only access and use your
                                        Account in accordance with this Agreement and for your personal use to find and
                                        communicate with potential dating partners. You may not authorize others to
                                        access or use your Account, and you may not assign or otherwise transfer your
                                        Account to any other person or entity. You acknowledge that we are not
                                        responsible from any third party access to your Account, particularly any such
                                        access that results from theft or misappropriation of your Account or Facebook
                                        account’s login credentials. You agree to immediately notify Boop
                                        of any disclosure or unauthorized use of your login credentials at <a
                                            href="mailto:mail@goboop.me">mail@goboop.me</a>.
                                    </li>
                                    <li><em><u>Accessing the Services from outside the United States</u>.</em>Using the
                                        Service may be prohibited or restricted in certain countries. If you use the
                                        Service from outside of the United States, you are fully responsible for
                                        complying with the laws and regulations of the territory from which you access
                                        or use the Services.
                                    </li>
                                    <li><em><u>You are responsible for any content you submit</u>.</em>You are solely
                                        responsible for, and assume all liability regarding, any information and content
                                        you provide or otherwise make available on the Services, including the text and
                                        images in your profile and in your communications with other Users (“User
                                        Content”).
                                    </li>
                                    <li><em><u>No contact information in your profile.</u></em>You agree not to post any
                                        contact information such as your phone number and email address in the body of
                                        your profile.
                                    </li>
                                </ul>
                                <h5>4. Changes to Agreement or Services</h5>
                                <p>We may update this Agreement at any time, in our sole discretion. If we do so, we’ll
                                    let you know either by posting the updated Agreement on the Site and App or through
                                    other communications. It’s important that you review the Agreement whenever we
                                    update them or you use the Services. If you continue to use the Services after we
                                    have posted an updated Agreement, you are agreeing to be bound by the updated
                                    Agreement. If you don’t agree to be bound by the updated Agreement, then, except as
                                    otherwise provided in Section 19(f)“Effect of Changes on Arbitration,” you may not
                                    use the Services anymore. Because our Services are evolving over time we may change
                                    or discontinue all or any part of the Services, at any time and without notice, at
                                    our sole discretion.</p>
                                <h5>5. General Prohibitions</h5>
                                <p>You agree not to do any of the following:</p>
                                <ul>
                                    <li>Post, upload, or transmit any User Content that (i)&nbsp;is threatening,
                                        defamatory, abusive, harassing, degrading, intimidating, fraudulent, false,
                                        misleading, deceptive, invasive, racist, or contains any type of offensively
                                        suggestive, inappropriate, or explicit language or confidential, non-public
                                        information about any third party; (ii)&nbsp;is threatening, vulgar, offensive,
                                        or pornographic; promotes or threatens violence or actions that are threatening
                                        to any person or entity; or contains nudity or graphic or gratuitous violence;
                                        (iii)&nbsp;promotes discrimination, bigotry, racism, hatred, harassment or harm
                                        against any individual or group; (iv)&nbsp;violates, or encourages any conduct
                                        that would violate, any applicable law or regulation or would give rise to civil
                                        liability; (v)&nbsp;infringes, misappropriates, or violates a third party’s
                                        trademark, patent, trade secret, copyright, moral rights or other proprietary
                                        rights, or rights of publicity or privacy; (vi)&nbsp;contains any type of
                                        unauthorized or unsolicited advertising or commercial communications (such as
                                        spam or multi-level marketing); (vii)&nbsp;impersonates any person or entity,
                                        including any Boop employees or representatives; or
                                        (vii)&nbsp;promotes illegal or harmful activities or substances;
                                    </li>
                                    <li>Collect User Content, personally-identifiable information or any other
                                        information, or otherwise access the Services, using automated means (such as
                                        harvesting bots, robots, spiders, scrapers, or other means);
                                    </li>
                                    <li>Advertise any product or service, including any engagement in multi-level
                                        marketing or “pyramid schemes” on the Services;
                                    </li>
                                    <li>Interfere with, or attempt to interfere with, the access of any user, host or
                                        network, including, without limitation, sending a virus, overloading, flooding,
                                        spamming, or mail-bombing the Services;
                                    </li>
                                    <li>Use any meta tags or other hidden text or metadata utilizing our trademarks,
                                        logos, URLs or product names without our express written consent;
                                    </li>
                                    <li>Use the Services, or any portion thereof, for any commercial purpose or for the
                                        benefit of any third party or in any manner not permitted by this Agreement;
                                    </li>
                                    <li>Forge any TCP/IP packet header or any part of the header information in any
                                        email or newsgroup posting, or in any way use the Services to send altered,
                                        deceptive or false source-identifying information;
                                    </li>
                                    <li>Attempt to decipher, decompile, disassemble or reverse engineer any of the
                                        software used to provide the Services;
                                    </li>
                                    <li>Attempt in any way to obtain the login information of or otherwise access an
                                        Account belonging to someone else;
                                    </li>
                                    <li>Bully, intimidate, or harass any User;</li>
                                    <li>Use, display, mirror or frame the Services or any individual element within the
                                        Services, our name, any Boop trademark, logo or other proprietary
                                        information, or the layout and design of any page or form contained on a page,
                                        without our express written consent;
                                    </li>
                                    <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
                                    <li>Engage in any activity or use any program that could disable, overburden, or
                                        impair the proper working or our set appearance of the Services, such as a
                                        denial of service attack, interference with page rendering, or using a program
                                        that interferes with other Services functionality; and
                                    </li>
                                    <li>Encourage or enable any other individual to do any of the foregoing.</li>
                                </ul>
                                <p>Although we’re not obligated to monitor access to or use of the Services or to review
                                    or edit any User Content, we have the right to do so for the purpose of operating
                                    the Services, to ensure compliance with this Agreement and to comply with applicable
                                    law or other legal requirements. We reserve the right, but are not obligated, to
                                    remove or disable access to any part of the Services, including any User Content, at
                                    any time and without notice, including, but not limited to, if we, at our sole
                                    discretion, consider any of the foregoing to be objectionable or in violation of
                                    this Agreement. We have the right to investigate violations of this Agreement or
                                    conduct that affects the Services. We may also consult and cooperate with law
                                    enforcement and other authorities, including by sharing any User Content, as
                                    requested by law enforcement or as we, in our sole discretion, deem necessary to
                                    protect the safety or wellbeing of our Users or any other person.</p>
                                <h5>6. Content Ownership</h5>
                                <ul>
                                    <li><em><u>Your license to us of User Content</u></em>. By submitting any User
                                        Content, you (i)&nbsp;represent and warrant that you have all the appropriate
                                        rights and permission (including getting permission from others in photos you
                                        provide)&nbsp;to provide such User Content to us, and (ii)&nbsp;grant us a
                                        perpetual, irrevocable, non-exclusive, transferable, sub-licensable,
                                        royalty-free, worldwide license to use any such User Content in connection with
                                        operating and providing the Services to you and other Users, for marketing and
                                        internal research purposes, and for external research purposes (for external
                                        research, any information will be provided in anonymized and aggregated form).
                                        You also represent and warrant that neither your User Content, nor your use and
                                        provision of your User Content to be made available through the Services, nor
                                        any use of your User Content by us on or through the Services will infringe,
                                        misappropriate or violate a third party’s intellectual property rights, or
                                        rights of publicity or privacy, or result in the violation of any applicable law
                                        or regulation.
                                    </li>
                                    <li><em><u>Deleting your User Content</u></em>. You may delete your User Content via
                                        the functionality of the Services. However, you understand that removed User
                                        Content may persist in backup copies for a reasonable period of time (but will
                                        not be available to others).
                                    </li>
                                    <li><em><u>Our ownership</u></em>. We do not claim any ownership rights in any User
                                        Content and nothing in this Agreement will be deemed to restrict any rights that
                                        you may have to use and exploit your User Content. Subject to the foregoing, We
                                        and our licensors exclusively own all right, title and interest in and to the
                                        Services, including all associated intellectual property rights. You acknowledge
                                        that the Services are protected by copyright, trademark, and other laws of the
                                        United States and foreign countries. You agree not to remove, alter or obscure
                                        any copyright, trademark, service mark or other proprietary rights notices
                                        incorporated in or accompanying the Services.
                                    </li>
                                    <li><em><u>Our license to you</u></em>. Subject to your compliance with this
                                        Agreement, we grant to you a limited, non-exclusive, non-transferable license,
                                        with no right to sublicense, to download, view, and, display the content made
                                        available on the Services solely in connection with your permitted use of the
                                        Services and solely for your personal and non-commercial purposes.
                                    </li>
                                </ul>
                                <h5>&nbsp;7. Feedback</h5>
                                <p>We welcome feedback, comments and suggestions for improvements to the Services
                                    (“Feedback”). You can submit Feedback by emailing us at <a
                                        href="mailto:mail@goboop.me">mail@goboop.me</a>or
                                    via the functionality of the Services. You grant to us a non-exclusive,
                                    transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free license,
                                    with the right to sublicense, under any and all intellectual property rights that
                                    you own or control to use, copy, modify, create derivative works based upon and
                                    otherwise exploit the Feedback for any purpose.</p>
                                <h5>8. One-Time Payments and Subscriptions</h5>
                                <p>Boop may offer from time to time certain premium services for a fee.
                                    You have the option of making a one-time payment (“One-Time Payment”)&nbsp;or
                                    purchasing a subscription (“Subscription”)&nbsp;for such premium services. By way of
                                    example, some of these premium services may include message read receipts,
                                    additional matches in a day, or profile picture appraisals.</p>
                                <ul>
                                    <li><u>General. </u>Whether you make a One-Time Payment or purchase a Subscription
                                        (each, a “Transaction”), you expressly authorize us (or our third-party payment
                                        processor, which may be the applicable App Store (defined below))&nbsp;to charge
                                        you for such Transaction. We may ask you to supply additional information
                                        relevant to your Transaction, including your credit card number, the expiration
                                        date of your credit card and your email and postal addresses for billing and
                                        notification (such information, “Payment Information”). You represent and
                                        warrant that you have the legal right to use all payment
                                        method(s)&nbsp;represented by any such Payment Information. When you initiate a
                                        Transaction, you authorize us to provide your Payment Information to third
                                        parties so we can complete your Transaction and to charge your payment method
                                        for the type of Transaction you have selected (plus any applicable taxes and
                                        other charges). You may need to provide additional information to verify your
                                        identity before completing your Transaction (such information is included within
                                        the definition of Payment Information).
                                    </li>
                                    <li><u>Subscriptions</u>. If you purchase a Subscription, you will be charged the
                                        Subscription fee, plus any applicable taxes, and other charges (“Subscription
                                        Fee”), at the beginning of your Subscription and each subscription period
                                        thereafter (the period of which is communicated to you at the time of your
                                        Subscription), at the then-current Subscription Fee. If you purchase a
                                        Subscription, unless you cancel your subscription before the end of your current
                                        Subscription period, your subscription will automatically renew, and we (or our
                                        third-party payment processor)&nbsp;will automatically charge you each year on
                                        the commencement of each renewed Subscription period, using the Payment
                                        Information you have provided. Your subscription will continue to automatically
                                        renew until you cancel your Subscription. By agreeing to this Agreement and
                                        electing to purchase a Subscription, you acknowledge that your Subscription has
                                        recurring payment features and you accept responsibility for all recurring
                                        payment obligations prior to cancellation of your Subscription by you or Boop. Your Subscription continues until cancelled by you or we terminate
                                        your access to or use of the Services or Subscription in accordance with this
                                        Agreement.
                                    </li>
                                    <li><u>Cancelling One-Time Payment or Subscription</u>. Except as explicitly set
                                        forth in Section 20below, YOUR PURCHASE IS FINAL AND YOU WILL NOT BE ABLE TO
                                        CANCEL THE PURCHASE AND/OR RECEIVE A REFUND OF ANY ONE-TIME PAYMENT OR
                                        SUBSCRIPTION FEE AT ANY TIME. if something unexpected happens in the course of
                                        completing a Transaction, we reserve the right to cancel your Transaction for
                                        any reason; if we cancel your Transaction we’ll refund any payment you have
                                        already remitted to us for such Transaction. Without limiting the foregoing, you
                                        may cancel your Subscription at any time, but please note that such cancellation
                                        will be effective at the end of the then-current Subscription period. EXCEPT AS
                                        SET FORTH ABOVE, YOU WILL NOT RECEIVE A REFUND OF ANY PORTION OF ANY
                                        SUBSCRIPTION FEE PAID FOR ANY SUBSCRIPTION PERIOD AT THE TIME OF CANCELLATION.
                                        You will be responsible for all Subscription Fees (plus any applicable taxes and
                                        other charges)&nbsp;incurred for the then-current Subscription period. You may
                                        cancel your subscription via the functionality of the App Store or the App. If
                                        you cancel, your right to use the Subscription features of the Services will
                                        continue until the end of your then current subscription period and will then
                                        terminate without further charges.
                                    </li>
                                </ul>
                                <h5>9. Rights and Terms for Apps</h5>
                                <ul>
                                    <li><u>Rights in App Granted by Boop</u>. Subject to your compliance
                                        with this Agreement, Boop grants to you a limited non-exclusive,
                                        non-transferable license, with no right to sublicense, to download and install a
                                        copy of the App on a mobile device or computer that you own or control and to
                                        run such copy of the App solely for your own personal non-commercial purposes.
                                        You may not copy the App, except for making a reasonable number of copies for
                                        backup or archival purposes. Except as expressly permitted in this Agreement,
                                        you may not: (i)&nbsp;copy, modify or create derivative works based on the App;
                                        (ii)&nbsp;distribute, transfer, sublicense, lease, lend or rent the App to any
                                        third party; (iii)&nbsp;reverse engineer, decompile or disassemble the App; or
                                        (iv)&nbsp;make the functionality of the App available to multiple users through
                                        any means. Boop reserves all rights in and to the App not
                                        expressly granted to you under this Agreement.
                                    </li>
                                    <li><u>Accessing the App from App Store</u>. Certain App Providers require us to
                                        pass one certain terms to you. The following terms apply to any App accessed
                                        through or downloaded from any app store or distribution platform (like the
                                        Apple App Store or Google Play)&nbsp;where the App may now or in the future be
                                        made available (each an “App Provider”). You acknowledge and agree that:
                                        <ul>
                                            <li>This Agreement is concluded between you and Boop, and not
                                                with the App Provider, and Boop (not the App Provider), is
                                                solely responsible for the App.
                                            </li>
                                            <li>The App Provider has no obligation to furnish any maintenance and
                                                support services with respect to the App.
                                            </li>
                                            <li>In the event of any failure of the App to conform to any applicable
                                                warranty, you may notify the App Provider, and the App Provider will
                                                refund the purchase price for the App to you (if applicable)&nbsp;and,
                                                to the maximum extent permitted by applicable law, the App Provider will
                                                have no other warranty obligation whatsoever with respect to the App.
                                                Any other claims, losses, liabilities, damages, costs or expenses
                                                attributable to any failure to conform to any warranty will be the sole
                                                responsibility of Boop.
                                            </li>
                                            <li>The App Provider is not responsible for addressing any claims you have
                                                or any claims of any third party relating to the App or your possession
                                                and use of the App, including, but not limited to: (i)&nbsp;product
                                                liability claims; (ii)&nbsp;any claim that the App fails to conform to
                                                any applicable legal or regulatory requirement; and (iii)&nbsp;claims
                                                arising under consumer protection, privacy, or similar legislation.
                                            </li>
                                            <li>In the event of any third party claim that the App or your possession
                                                and use of that App infringes that third party’s intellectual property
                                                rights, Boop will be solely responsible for the
                                                investigation, defense, settlement and discharge of any such
                                                intellectual property infringement claim to the extent required by this
                                                Agreement.
                                            </li>
                                            <li>The App Provider, and its subsidiaries, are third-party beneficiaries of
                                                this Agreement as related to your license to the App, and that, upon
                                                your acceptance of this Agreement, the App Provider will have the right
                                                (and will be deemed to have accepted the right)&nbsp;to enforce this
                                                Agreement as related to your license of the App against you as a
                                                third-party beneficiary thereof.
                                            </li>
                                            <li>You represent and warrant that (i)&nbsp;you are not located in a country
                                                that is subject to a U.S. Government embargo, or that has been
                                                designated by the U.S. Government as a terrorist-supporting country; and
                                                (ii)&nbsp;you are not listed on any U.S. Government list of prohibited
                                                or restricted parties.
                                            </li>
                                            <li>You must also comply with all applicable third party terms of service
                                                when using the App.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <h5>10. Risk Assumption and Precautions</h5>
                                <p>You assume all risk when using the Services, including but not limited to all of the
                                    risks associated with any online or offline interactions with others, including
                                    dating. We are not responsible for the conduct of our Users or their compatibility
                                    in any capacity with you or other Users. You agree to take all necessary and
                                    appropriate precautions when sharing your personal information, communicating with
                                    other Users, and meeting other Users in person. You understand that we do not
                                    (except as described in this Agreement)&nbsp;conduct background checks, or verify
                                    the identity, profile pictures, or any other User Content. You are under no
                                    obligation to use the Services, continue to communicate with any User, or to match
                                    with or meet anyone from the Services.</p>
                                <h5>11. No Guarantees</h5>
                                <p>You understand that Boop does not guarantee you any matches, does not
                                    guarantee any number or frequency of matches, or the accuracy, availability, or
                                    other attributes of any User. You understand that Boop makes no
                                    guarantees about the Services, either explicit or implied, including your
                                    compatibility with any other User.</p>
                                <h5>12. Reporting of Violations; General Contact Info.</h5>
                                <p>If you discover any violation of the Agreement by others, please report it to us
                                    at <a
                                        href="mailto:mail@goboop.me"><strong>mail@goboop.me</strong></a>.
                                    You may also use this address to contact us with any questions you may have about
                                    the Services.</p>
                                <h5>13. Third Party Links</h5>
                                <p>The Services (including the App)&nbsp;may contain links to third-party websites or
                                    resources. We provide these links only as a convenience and are not responsible for
                                    the content, products or services on or available from those websites or resources
                                    or links displayed on such websites. You acknowledge sole responsibility for and
                                    assume all risk arising from, your use of any third-party websites or resources.</p>
                                <h5>14. Disclaimer of Warranty</h5>
                                <p>THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE
                                    FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                                    FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES
                                    ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the
                                    Services will meet your requirements or be available on an uninterrupted, secure, or
                                    error-free basis. We make no warranty regarding the quality, accuracy, timeliness,
                                    truthfulness, completeness or reliability of any Content.</p>
                                <h5>15. Limitation of Liability</h5>
                                <ul>
                                    <li>NEITHER BOOP NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING,
                                        OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY
                                        OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST
                                        SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE
                                        INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE
                                        SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR FROM
                                        THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY,
                                        CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL
                                        THEORY, AND WHETHER OR NOT BOOP OR ANY OTHER PARTY HAS BEEN
                                        INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
                                        HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO
                                        NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                                        INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                                    </li>
                                    <li>IN NO EVENT WILL BOOP’S TOTAL LIABILITY ARISING OUT OF OR IN
                                        CONNECTION WITH THIS AGREEMENT OR FROM THE USE OF OR INABILITY TO USE THE
                                        SERVICES EXCEED THE AMOUNTS YOU HAVE PAID TO BOOP FOR USE OF THE
                                        SERVICES OR FIFTY DOLLARS ($50), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO
                                        BOOP, AS APPLICABLE.
                                    </li>
                                    <li>THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
                                        ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN BOOP AND YOU.
                                    </li>
                                </ul>
                                <h5>16. Indemnification</h5>
                                <p>You will indemnify and hold harmless Boop and its officers, directors,
                                    employees and agents, from and against any claims, disputes, demands, liabilities,
                                    damages, losses, and costs and expenses, including, without limitation, reasonable
                                    legal and accounting fees arising out of or in any way connected with (i)&nbsp;your
                                    access to or use of the Services, (ii)&nbsp;your User Content, or (iii)&nbsp;your
                                    violation of this Agreement.</p>
                                <h5>17. Termination</h5>
                                <p>We may terminate your access to and use of the Services, at our sole discretion, at
                                    any time and without notice to you. You may delete your Account, deactivate your
                                    membership, or put your Account on hold at any time by sending an email to us at <a
                                        href="mailto:mail@goboop.me"><strong>mail@goboop.me</strong></a>or
                                    via the functionality of the App; deleting your Account is your sole method of
                                    terminating this Agreement. Upon termination of this Agreement, the following will
                                    survive Sections 3(d),6(c),7,14,15,16,18,19,21, and this sentence of Section 17.</p>
                                <h5>18. Governing Law and Forum Choice</h5>
                                <p>This Agreement and any action related thereto will be governed by the Federal
                                    Arbitration Act, federal arbitration law, and the laws of the State of California,
                                    without regard to its conflict of laws provisions. Except as otherwise expressly set
                                    forth in Section 19“Dispute Resolution,” the exclusive jurisdiction for all Disputes
                                    (defined below)&nbsp;that you and Boop are not required to arbitrate
                                    will be the state and federal courts located in the Northern District of California,
                                    and you and Boop each waive any objection to jurisdiction and venue in
                                    such courts.</p>
                                <h5>19. Dispute Resolution</h5>
                                <ul>
                                    <li><u>Mandatory Arbitration of Disputes.</u>We each agree that any dispute, claim
                                        or controversy arising out of or relating to this Agreement or the breach,
                                        termination, enforcement, interpretation or validity thereof or the use of the
                                        Services (collectively, “Disputes”)&nbsp;will be resolved solely by binding,
                                        individual arbitration and not in a class, representative or consolidated action
                                        or proceeding. You and Boop agree that the U.S. Federal
                                        Arbitration Act governs the interpretation and enforcement of this Agreement,
                                        and that you and Boop are each waiving the right to a trial by
                                        jury or to participate in a class action. This arbitration provision shall
                                        survive termination of this Agreement.
                                    </li>
                                    <li><u>Exceptions and Opt-out</u>. As limited exceptions to Section 19(a)above:
                                        (i)&nbsp;you may seek to resolve a Dispute in small claims court if it
                                        qualifies; and (ii)&nbsp;we each retain the right to seek injunctive or other
                                        equitable relief from a court to prevent (or enjoin)&nbsp;the infringement or
                                        misappropriation of our intellectual property rights. In addition, you will
                                        retain the right to opt out of arbitration entirely and litigate any Dispute if
                                        you provide us with written notice of your desire to do so by email at <a
                                            href="mailto:mail@goboop.me">mail@goboop.me</a>within
                                        thirty (30)&nbsp;days following the date you first agree to this Agreement.
                                    </li>
                                    <li><u>Conducting Arbitration and Arbitration Rules</u>. The arbitration will be
                                        conducted by the American Arbitration Association (“AAA”)&nbsp;under its
                                        Consumer Arbitration Rules (the “AAA Rules”)&nbsp;then in effect, except as
                                        modified by this Agreement. The AAA Rules are available at <a
                                            href="http://www.adr.org/">www.adr.org</a>or by calling 1-800-778-7879. A
                                        party who wishes to start arbitration must submit a written Demand for
                                        Arbitration to AAA and give notice to the other party as specified in the AAA
                                        Rules. The AAA provides a form Demand for Arbitration at <a
                                            href="http://www.adr.org/aaa/ShowPDF?doc=ADRSTG_004175">www.adr.org</a>.
                                    </li>
                                    <li>If your claim is for U.S. $10,000 or less, you may choose whether the
                                        arbitration will be conducted solely on the basis of documents submitted to the
                                        arbitrator, through a telephonic or video-conference hearing, or by an in-person
                                        hearing as established by the AAA Rules.&nbsp; If your claim exceeds U.S.
                                        $10,000, the right to a hearing will be determined by the AAA Rules. Any
                                        arbitration hearings will take place in the county (or parish)&nbsp;where you
                                        live, unless we both agree to a different location. The parties agree that the
                                        arbitrator shall have exclusive authority to decide all issues relating to the
                                        interpretation, applicability, enforceability and scope of this arbitration
                                        agreement.
                                    </li>
                                    <li><u>Arbitration Costs</u>. Payment of all filing, administration and arbitrator
                                        fees will be governed by the AAA Rules. We’ll pay for all filing, administration
                                        and arbitrator fees and expenses if your Dispute is for less than $10,000,
                                        unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration
                                        we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them
                                        from you. If you prevail in arbitration you will be entitled to an award of
                                        attorneys’ fees and expenses to the extent provided under applicable law.
                                    </li>
                                    <li><u>Class Action Waiver</u>.&nbsp; YOU AND BOOP AGREE THAT EACH MAY
                                        BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT
                                        AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                                        PROCEEDING.&nbsp;Further, if the parties’ dispute is resolved through
                                        arbitration, the arbitrator may not consolidate another person’s claims with
                                        your claims, and may not otherwise preside over any form of a representative or
                                        class proceeding.&nbsp; If this specific provision is found to be unenforceable,
                                        then the entirety of this Dispute Resolution section shall be null and void.
                                    </li>
                                    <li><u>Effect of Changes on Arbitration.</u>Notwithstanding the provisions of
                                        Section 4“Changes to Agreement or Services” above, if Boop changes
                                        any of the terms of this Section 19“Dispute Resolution” after the date you first
                                        accepted this Agreement (or accepted any subsequent changes to this Agreement),
                                        you may reject any such change by sending us written notice (including by email
                                        to <a
                                            href="mailto:mail@goboop.me">mail@goboop.me</a>)&nbsp;within
                                        30 days of the date such change became effective, as indicated in the “Last
                                        Updated” date above or in the date of Boop’s email to you
                                        notifying you of such change. By rejecting any change, you are agreeing that you
                                        will arbitrate any Dispute between you and Boop in accordance with
                                        the terms of this Section 19“Dispute Resolution” as of the date you first
                                        accepted this Agreement (or accepted any subsequent changes to this Agreement).
                                    </li>
                                    <li><u>Severability.</u>&nbsp;With the exception of any of the provisions in Section
                                        19(e)of this Agreement (“Class Action Waiver”), if an arbitrator or court of
                                        competent jurisdiction decides that any part of this Agreement is invalid or
                                        unenforceable, the other parts of this Agreement will still apply.
                                    </li>
                                </ul>
                                <h5>20. State-Specific Terms</h5>
                                <ul>
                                    <li><em><u>If you live in certain states, you may cancel this agreement within 3
                                        business days.</u></em></li>
                                </ul>
                                <p>The following provisions are added to this Agreement for subscribers residing in
                                    Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, North
                                    Carolina, Ohio and Wisconsin:</p>
                                <p>You, the buyer, may cancel this Agreement, without penalty or obligation, at any time
                                    prior to midnight of the third business day following the original date of this
                                    contract, excluding Sundays and holidays. To cancel this Agreement, mail or deliver
                                    a signed and dated notice which states that you, the buyer, are canceling this
                                    Agreement, or words of similar effect. This notice shall be sent to:</p>
                                <p>Boop<br />mail@goboop.me</p>
                                <p>Please include your Boop email address in any correspondence or your
                                    refund may be delayed. If you cancel, Boop will return, within ten
                                    days of the date on which you give notice of cancellation, any payments you have
                                    made. If you send or deliver the notice to cancel your subscription agreement within
                                    such three day period, we will refund the full amount of your subscription.</p>
                                <p>In the event that you die before the end of your subscription period, your estate
                                    shall be entitled to a refund of that portion of any payment you had made for your
                                    subscription which is allocable to the period after your death. In the event that
                                    you become disabled (such that you are unable to use the Services)&nbsp;before the
                                    end of your subscription period, you shall be entitled to a refund of that portion
                                    of any payment you had made for your subscription which is allocable to the period
                                    after your disability by providing the company notice at the same address as listed
                                    above.</p>
                                <ul>
                                    <li><em><u>California Users</u></em></li>
                                </ul>
                                <p>California Civil Code Section 1789.3 requires us to provide you with the following
                                    information: You may file grievances and complaints with the Complaint Assistance
                                    Unit of the Division of Consumer Services of the California Department of Consumer
                                    Affairs, 400 R Street, Suite 1080, Sacramento, California, 95814, or by telephone at
                                    (916)&nbsp;445-1254 or (800)&nbsp;952-5210, or by email at <a
                                        href="mailto:dca@dca.ca.gov"><strong>dca@dca.ca.gov</strong></a>.</p>
                                <h5>21. General Terms</h5>
                                <ul>
                                    <li><u>Entire Agreement</u>. This Agreement constitute the entire and exclusive
                                        understanding and agreement between Boop and you regarding the
                                        Services and Content, and this Agreement supersede and replace any and all prior
                                        oral or written understandings or agreements between Boop and you
                                        regarding the Services and Content. If any provision of this Agreement is held
                                        invalid or unenforceable by an arbitrator or a court of competent jurisdiction,
                                        that provision will be enforced to the maximum extent permissible and the other
                                        provisions of this Agreement will remain in full force and effect. You may not
                                        assign or transfer this Agreement, by operation of law or otherwise, without
                                        Boop’s prior written consent. Any attempt by you to assign or
                                        transfer this Agreement, without such consent, will be null. Boop
                                        may freely assign or transfer this Agreement without restriction. Subject to the
                                        foregoing, this Agreement will bind and inure to the benefit of the parties,
                                        their successors and permitted assigns.
                                    </li>
                                    <li><u>Notices</u>. Any notices or other communications provided by Boop under this Agreement, including those regarding modifications to this
                                        Agreement, will be given: (i)&nbsp;via email; or (ii)&nbsp;by posting to the
                                        Services. For notices made by e-mail, the date of receipt will be deemed the
                                        date on which such notice is transmitted.
                                    </li>
                                    <li><u>Waiver of Rights</u>. Boop’s failure to enforce any right or
                                        provision of this Agreement will not be considered a waiver of such right or
                                        provision. The waiver of any such right or provision will be effective only if
                                        in writing and signed by a duly authorized representative of Boop.
                                        Except as expressly set forth in this Agreement, the exercise by either party of
                                        any of its remedies under this Agreement will be without prejudice to its other
                                        remedies under this Agreement or otherwise.
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <div
                            className="hr hr-default  av-desktop-hide av-medium-hide av-small-hide av-mini-hide  avia-builder-el-6  el_after_av_textblock  avia-builder-el-last  ">
                            <span className="hr-inner "><span className="hr-inner-style"></span></span></div>
                    </div>
                </div>
            </Content>
    );
}

export default Privacy;
